$.fn.initizeTabs = function () {
    Object.values(this).forEach((item) => {
        const element = $(item)
        if (element.is('.tabs')) {
            element.data('tabs', new Tabs(element))
        }
    })
}

class Tabs {
    constructor(element) {
        this.id = element.attr('id') ?? Math.random().toString(16).substring(2)
        this.element = element.attr('id', this.id)
        this.currentTab = this.element.find('li.nav-item:first').attr('data-tab-id')
        this.tabs = Object.values(this.element.find('li.nav-item')).reduce((items, item) => {
            const element = $(item)
            if (element.is('li.nav-item')) {
                items.push(element.attr('data-tab-id'))
            }
            return items
        }, [])

        this.element.find(`[data-tab-id="${this.currentTab}"] > .nav-link`).addClass('active')
        this.element.find('.card-body > [data-tab]:not(:first)').hide()

        this.element.find('li.nav-item').on('click', (e) => {
            this.setTab(e.currentTarget.dataset.tabId) // Accessible from $( ... ).data('tabs')
        })
    }

    setTab(tabId) {
        this.element.find('li.nav-item > .nav-link').removeClass('active')
        this.element.find(`li.nav-item[data-tab-id="${tabId}"] > .nav-link`).addClass('active')
        this.element.find(`.card-body [data-tab]`).hide()
        this.element.find(`.card-body [data-tab="${tabId}"]`).show()
        return this
    }

    nextTab() {
        const nextTab = this.tabs.indexOf(this.currentTab) + 1 < this.tabs.length ?
            this.tabs[this.tabs.indexOf(this.currentTab) + 1] :
            this.tabs[0]
        this.setTab(nextTab)
        this.currentTab = nextTab
    }
    prevTab() {
        const prevTab = this.tabs.indexOf(this.currentTab) - 1 >= 0 ?
            this.tabs[this.tabs.indexOf(this.currentTab) - 1] :
            this.tabs[this.tabs.length - 1]
        this.setTab(prevTab)
        this.currentTab = prevTab
    }
}
