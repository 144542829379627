const modalTemplate = `
<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" style="display: none;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>
`

const closeTemplate = `<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>`
const closeButtonTemplate = `<button type="button" class="btn btn-secondary" data-dismiss="modal">Sluiten</button>`

const defaultOptions = {
    // Show close in top corner
    close: true,
    // Show close button in modal footer
    closeButton: true,
    // Define modal size, default is Bootstrap default
    size: null,
    // Determines if the modal should be destroyed after closing
    autoDestroy: true
}

class Modal {
    constructor (setOptions) {
        this.id = Math.random().toString(16).substring(2)
        this.modalElement = $(modalTemplate).attr('id', this.id)
        this.body(setOptions.content)
        this.title(setOptions.title)

        // Merge user options with defaults
        this.options = Object.assign({}, defaultOptions, setOptions)

        if (this.options.close) {
            this.modalElement.find('.modal-header:first-child').append(closeTemplate)
        }
        if (this.options.closeButton) {
            this.modalElement.find('.modal-footer').append(closeButtonTemplate)
        }

        switch (this.options.size) {
            case 'large':
                this.modalElement.find('.modal-dialog').addClass('modal-lg')
                break;
            case 'small':
                this.modalElement.find('.modal-dialog').addClass('modal-sm')
                break;
        }
    }

    body(content, callback = () => {}) {
        this.modalElement.find('.modal-body').html(content)
        callback()
        return this
    }
    title(value) {
        this.modalElement.find('.modal-title').text(value)
        return this
    }

    show() {
        if (this.options.autoDestroy) {
            this.modalElement.on('hidden.bs.modal', (e) => {
                this.modalElement.remove()
            })
        }

        this.modalElement.modal('show')
    }
    hide() {
        this.modalElement.modal('hide')
    }
    destroy() {
        this.modalElement.modal('hide')
        this.modalElement.on('hidden.bs.modal', (e) => {
            this.modalElement.remove()
        })
    }
}


$.fn.createModal = function (options) {
    return new Modal(options)
}
