// Laravel JS Bootstrapper
require('./bootstrapper.js');

// jQuery
window.$ = window.jQuery = require('jquery');
require('./vendor/inspinia/plugins/jquery-ui/jquery-ui.min.js');
require('./vendor/inspinia/plugins/dualListbox/jquery.bootstrap-duallistbox.js');
require('jquery-slimscroll/jquery.slimscroll');

// Twitter Bootstrap
require('bootstrap');

// DataTables
require('datatables.net');
require('datatables.net-bs4');

// Popper JS
window.Popper = require('popper.js');

// metisMenu
require('metismenu');

// Inspinia
require('./vendor/inspinia/inspinia.js');

// Load plugins
require('./plugins/jqTabs.js')
require('./plugins/modals.js')

// Select2
require('select2/dist/js/select2.full');

// Prism.js
const Prism = require('prismjs');
require('prismjs/components/prism-json');

// Document ready
$.when($.ready).then(function () {
    // Prevent default class
    $('.prevent-default').click(e => {
        e.preventDefault();
    })
});
